<template>
  <div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column
            label="开门时间"
            prop="openBed"
            align="center"
            header-align="center"
            width="180"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="放床时间"
            align="center"
            header-align="center"
            prop="repayBed"
            width="180"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="关门时间"
            align="center"
            header-align="center"
            prop="closeBed"
            width="180"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="rfid"
            align="center"
            header-align="center"
            prop="rfid"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备名称"
            align="center"
            header-align="center"
            prop="deviceName"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备id"
            align="center"
            header-align="center"
            prop="deviceId"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备imei"
            align="center"
            header-align="center"
            prop="imei"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="货道号"
            align="center"
            header-align="center"
            prop="cargoWayNo"
            :formatter="commonFormatter"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
export default {
  name: "OfflineReturnRecord",
  mixins:[tableUse],
  data() {
    return {
      tableData: [
      ],
    }
  },
  methods:{
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize: this.pageSize
      }
      this.$axios.post("/sys-api/device-api/getOffLine",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">
.table-container{
  height: calc(100% - 50px - 20px);
  padding-top: 20px;
}
</style>
